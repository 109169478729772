.hero-center-wrapper {
    margin-top: 75px;
}

.hero-aux-section {
    padding: 195px 50px 0px !important;
}

.vertical-div-line-light {
    display: none;
}

h1.hero-title,
h1.hero-title strong {
    font-family: "Cinzel", serif !important;
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 500;
    @media screen and (max-width: 767px) {
        font-size: 1.6rem !important;
        line-height: 1.2;
    }
}

body.page-body.layout-homepage h1.hero-title {
    @media screen and (max-width: 767px) {
        padding: 0 25px;
    }
}

body.page-body.layout-post h1.hero-title,
body.page-body.layout-post h1.hero-title strong {
    @media screen and (max-width: 767px) {
        font-size: 1.2rem !important;
    }
}

.list-categories a strong {
    @media screen and (max-width: 767px) {
        font-size: 0.6rem;
    }
}

h2 {
    font-size: 38px;
    line-height: 48px;
    @media screen and (max-width: 767px) {
        text-align: center;
        font-size: 1.5rem !important;
        line-height: 1.25;
    }
}

.desktop {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.mobile {
    display: none;
    position: relative;
    @media screen and (max-width: 767px) {
        display: inherit;
    }
}

@media screen and (max-width: 991px) {
    section#subscribe-form a.button-light.w-button {
        text-align: center !important;
        margin: 0 auto;
        display: table;
    }
    .heading-9 {
        margin-bottom: 0 !important;
        font-size: 25px !important;
    }
    .heading-20 {
        font-size: 2rem !important;
        text-align: center !important;
    }
    .page-image {
        margin-bottom: 15px !important;
        max-width: inherit;
    }
}

.col-12 {
    width: 100%;
}

body.page-body.layout-post {
    .author {
        font-size: 1.2rem;
        margin: 0;
    }
    .aux-wrapper.post-container,
    .aux-section .w-dyn-list {
        max-width: 800px !important;
        margin: 0 auto;
        float: none !important;
        img {
            width: 100%;
            margin-bottom: 15px !important;
        }
        .list-categories {
            margin-top: 10px;
            margin-bottom: 20px;
            display: flex;
        }
    }
    .collection-list.w-dyn-items {
        top: 20px;
    }
}

.form-alerts {
    display: none;
    .alert {
        border: 1px solid #d0b999;
        color: #56442a;
        padding: 8px;
    }
}

.text-left {
    text-align: left;
}

.action-bar {
    background: #0f1921;
    padding: 5px 0;
    position: fixed;
    top: 0;
    display: -ms-grid;
    display: grid;
    width: 100%;
    z-index: 11111;
    @media screen and (max-width: 767px) {
        display: none;
    }
    .hero-contact-info-grid {
        margin-bottom: 0;
    }
    a {
        color: white !important;
    }
}

@media screen and (min-width: 768px) {
    .navbar.w-nav {
        top: 36px;
    }
}

a.nav-link.w-nav-link.w--nav-link-open {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.hero-section {
    @media screen and (max-width: 767px) {
        background-position: 70% 20px;
    }
    .hero-bottom-wrapper {
        height: 100%;
        .hero-center-wrapper {
            margin-top: 140px;
            @media screen and (max-width: 767px) {
                margin-top: 45px;
            }
        }
        .hero-contact-info-grid {
            margin-bottom: 0;
        }
    }
}

.page-body .hero-aux-section {
    background-position: 0 7%;
    @media screen and (max-width: 767px) {
        background-position: 66% center;
        padding: 85px 50px 0px !important;
    }
}

.home-services-wrapper {
    margin-top: 0;
    margin-bottom: 0;
}

.w-layout-grid.home-service-grid .services-main-photo {
    background-position: 29% 50%;
    height: 77%;
    margin-top: 100px;
}

.home-services-list-wrapper.w-dyn-list {
    box-shadow: none !important;
}

.news-collection-item {
    justify-content: flex-start !important;
}

div#free-quote h3.heading-9 {
    width: 100%;
}

#mc_embed_signup form {
    padding: 0 !important;
    overflow: hidden;
    background: #ac6319;
}

input#mce-EMAIL {
    width: 79% !important;
    float: left;
    border-radius: 0 !important;
    height: 52px !important;
    margin: 0px !important;
    line-height: 180% !important;
    outline: none !important;
    padding: 8px 12px !important;
    color: #333333 !important;
    vertical-align: middle !important;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    font-size: 14px !important;
    @media screen and (max-width: 767px) {
        width: 100% !important;
        text-align: center;
    }
}

div#mc_embed_signup_scroll .clear {
    width: 21% !important;
    @media screen and (max-width: 767px) {
        width: 100% !important;
    }
}

input#mc-embedded-subscribe {
    width: 100% !important;
    min-width: 0 !important;
    height: 100% !important;
    padding: 20.5px 25px 16.5px 25px !important;
    border-radius: 0 !important;
    border-color: #b29874 !important;
    background-color: #ac6319 !important;
    -webkit-transition: border-color 200ms ease, background-color 200ms ease;
    transition: border-color 200ms ease, background-color 200ms ease;
    font-size: 12.5px !important;
    line-height: 15px !important;
    font-weight: 700 !important;
    letter-spacing: 2.5px !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
    outline: none !important;
    &:hover {
        border-color: #f2cf9e !important;
        background-color: #f2cf9e !important;
        color: #fff !important;
    }
}

@media screen and (max-width: 767px) {
    .footer-flex-container {
        text-align: center;
    }
    .brand {
        height: auto !important;
        width: 100% !important;
        .brand-logo {
            width: 100%;
            height: auto;
            margin: 0;
        }
    }
    .navbar.w-nav {
        grid-template-columns: 2fr 3fr;
    }
    .bold-text-7 {
        font-size: 43px;
    }
    a.footer-link-2 {
        text-align: center !important;
    }
    .text-block-5 {
        text-align: center;
    }
}

// Blog
body.page-body.layout-blog,
body.page-body.layout-category,
body.page-body.layout-post {
    .list-categories {
        margin: 25px 0 8px;
        .box-color {
            margin: 0 4px;
            background: #b29874;
            a {
                padding: 12px;
                display: block;
                color: white;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .list-categories {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .dropbtn {
            background-color: #b29874;
            color: white;
            padding: 8px 15px 6px;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            border: none;
            text-transform: uppercase;
            font-family: Roboto, sans-serif;
        }
        .dropdown {
            position: relative;
            display: inline-block;
            z-index: 111;
            margin-top: 30px;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #fbfbfb;
            min-width: 265px;
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            z-index: 1;
            padding: 7px 0;
        }
        .dropdown-content a {
            color: black;
            padding: 10px 16px 8px;
            text-decoration: none;
            display: block;
            text-align: left;
        }
        .dropdown-content a:hover {
            background-color: #ddd;
        }
        .dropdown:hover .dropdown-content {
            display: block;
        }
    }
    @media screen and (min-width: 768px) {
        .aux-section {
            justify-content: inherit !important;
            align-items: inherit !important;
            display: block !important;
            overflow: hidden;
            .categories {
                width: 20% !important;
                float: left;
                a {
                    margin-bottom: 5px;
                }
            }
            .w-dyn-list {
                width: 78% !important;
                float: right;
                .collection-list {
                    -ms-grid-columns: 1fr 1fr 1fr !important;
                    grid-template-columns: 1fr 1fr 1fr !important;
                }
            }
        }
    }
}

.icons-s {
    margin-top: 10px;
    a {
        margin: 0 3px;
    }
}

.whatsapp-fixed-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    box-sizing: border-box;
    padding: 0.75rem;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
    line-height: 1;
    border-radius: 100%;
    color: #fff;
    background-color: #25d366;
    z-index: 1050;
    box-shadow: 0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 1px rgb(60 64 67 / 16%);
    transition: box-shadow 0.2s ease;
    svg {
        width: 85%;
        height: auto;
    }
}